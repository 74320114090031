import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme"
import GeneralFooter from "../components/general_footer"
import StartupProfile from "../components/startup"
import recordFilter from "../components/defaults/filters/record"

const StartupPage = ({ data, context }) => {
  const seo = {
    frontmatter: {
      title: data.airtable.data.startup,
      slug: `startups/${recordFilter(data.airtable.recordId)}`,
    },
  }

  return (
    <Layout dark seo={seo}>
      <StartupProfile
        data={data.airtable.data}
        recordId={data.airtable.recordId}
      />
      <GeneralFooter theme={color.main_light} />
    </Layout>
  )
}

export default StartupPage

export const query = graphql`
  query Startups($id: String!) {
    airtable(id: { eq: $id }, table: { eq: "startups" }) {
      id
      recordId
      data {
        password_protected
        startup
        display_company
        vertical
        startup_program
        country
        city
        founded
        number_employees
        number_pilots
        stage
        users {
          recordId
          data {
            include
            name
            profession
            image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 350)
                }
              }
            }
          }
        }
        agenda {
          recordId
          data {
            agenda_description {
              childMarkdownRemark {
                html
                excerpt(format: HTML, truncate: true, pruneLength: 512)
              }
            }
            password_protected
            agenda_start_time
            agenda_publish
            agenda_tags
            agenda_title
            agenda_duration
            agenda_teaser
            agenda_dial_in
            agenda_stage
            agenda_rearview {
              data {
                publish
              }
            }
            agenda_partners {
              recordId
              data {
                name
              }
            }
            agenda_startups {
              recordId
              data {
                startup
                password_protected
              }
            }
          }
        }
        rearview {
          recordId
          data {
            sort
            publish
            password_protected
            rearview_tags
            rearview_thumbnail {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                    width: 300
                  )
                }
              }
            }
            rearview_description {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 240)
              }
            }
            rearview_release
            rearview_title
            rearview_partners {
              data {
                name
              }
            }
            rearview_startups {
              recordId
              data {
                startup
                password_protected
              }
            }
            rearview_agenda {
              data {
                agenda_start_time
              }
            }
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        url
        twitter
        linkedin
        casestudy {
          data {
            cs_title
            cs_publish
            cs_teaser {
              childMarkdownRemark {
                html
              }
            }
            cs_file {
              localFiles {
                publicURL
              }
            }
            cs_thumbnail {
              localFiles {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
                }
              }
            }
            cs_agenda {
              recordId
              data {
                agenda_title
                agenda_start_time
              }
            }
            cs_partner {
              data {
                name
              }
            }
            cs_downloadable
            cs_contact {
              recordId
              data {
                include
                name
                profession
                partner_company {
                  data {
                    name
                  }
                }
                email
                mail_public
              }
            }
          }
        }
        pressrelease {
          localFiles {
            publicURL
            extension
            name
          }
        }
      }
    }
  }
`
